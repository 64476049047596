'use client'

import { createContext, useContext, useMemo } from 'react'

type TripsProviderFields = {
  currentSeason: Contentful.Models.Trip['preferredSeason'] | null
  currentTrip: Contentful.Models.Trip
  seasons: Contentful.Models.TripSeason[]
  trips: Contentful.Models.Trip[]
}

type TripsProviderProps = Omit<TripsProviderFields, 'defaultTrip'>

const TripsContext = createContext<TripsProviderFields>({} as never)

export function useTripsContext() {
  return useContext(TripsContext)
}

export function TripProvider({
  children,
  currentSeason,
  currentTrip,
  seasons,
  trips,
}: Readonly<React.PropsWithChildren<TripsProviderProps>>) {
  const value = useMemo(
    () => ({
      currentSeason,
      currentTrip,
      seasons,
      trips,
    }),
    [currentSeason, currentTrip],
  )

  return <TripsContext.Provider value={value}>{children}</TripsContext.Provider>
}
